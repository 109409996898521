import addressComp from "../address-comp.vue";
import commonApiService from "../../common/js/common-api-service";
import EncryptUtility from "../../utility/js/encrypt-utility";
import breadcrumbComp from "../../common/breadcrumb-comp.vue";
import AddFavourite from "../../utility/js/addFav";
import salesService from "./sales-order-service";
import moment from "moment";
import BackToTop from "../../common/back-to-top.vue";
import utility from "../../../shared/utility";
export default {
  name: "salesOrderAdd",
  data() {
    return {
      businessName: "",
      fab: false,
      disableSubmitBtn: false,
      disableDraftBtn: false,
      showShipAddDDL: false,
      showReturnAddDDL: false,
      disableSignature: false,
      disableSatDel: false,
      showNewHireDate: true,
      showOrderedDate: true,
      shipCount: 0,
      returnCount: 0,
      disablePartRecieve: false,
      disableQty: false,
      showStockStatus: false,
      stockMessage: "",
      projectName: "",
      custSearchKey: "",
      disableRetQty: false,
      buildPartResultList: [],
      partBuildPartNum: "",
      shipSearchKey: "",
      partShipSearchKey: "",
      editPartQty: "",
      showExpectedSr: false,
      showEditShipToDialog: false,
      showEditReturnToDialog: false,
      showFlex: false,
      disable_Step6: false,
      showLineDelete: false,
      deleteItem: false,
      editFlex: false,
      editPartNum: "",
      list_flex_field_Att: "",
      list_flex_value: "",
      required: [(v) => !!v || "Field is required"],
      rmaValidataion: [
        (v) => !!v || "Field is required",
        (v) =>
          /^(?:[ A-Za-z0-9\s\S]){3,25}$/.test(v) ||
          "Please enter at least one text or number, RMA should be greater than 3 characters and less than 25 characters",
      ],
      priceValid: [
        (v) => parseInt(v) >= 0 || "Field is required",
        (v) => /^\$?([0-9]{1,3},([0-9]{3},)*[0-9]{3}|[0-9]+)(.[0-9][0-9])?$/.test(v) || "Upto 2 decimal places are allowed",
      ],
      max20RuleReq: [(v) => !!v || "Field is required", (v) => (v || "").length <= 20 || "Max Length must be 20 characters or less"],
      max30RuleReq: [
        (v) => !!v || "Field is required",
        (v) => (v || "").length <= 30 || "Max Length must be 30 characters or less",
        (v) => (v || "").length >= 5 || "Min Length must be 5 characters or More",
      ],
      line_required: [(v) => parseInt(v) >= 0 || "Field is required"],
      qtyRule: [
        (v) => parseInt(v) > 0 || "Field should be greater than 0",
        (v) => parseInt(v) < 2147483647 || "Field should be less than 2,147,483,647",
      ],
      returnPartName: "",
      shippingReq: false,
      returnReq: false,
      editTrack: "",
      editReturn_carrier: "",
      disableAuto: true,
      return_carrier: "",
      draftData: [],
      buildRow: false,
      showBuildExpand: false,
      showShipByDate: true,
      partReturnSearchKey: "",
      returnResultList: [],
      editWarrantyStatus: "",
      notRequired: false,
      editProblemDesc: "",
      warrantyList: [],
      deleteBuildDialog: false,
      LineTypeList: [],
      probDesc: "",
      disableLine: false,
      returnSearchKey: "",
      disableSubmit: true,
      returnList: [],
      shipWareList: [],
      trackNum: "",
      partResultList: [],
      showDelete: false,
      selectedbuildPart: "",
      serialNum: "",
      custLine: "",
      headersBuild: [
        {
          text: "Part Number",
          align: "start",
          value: "bo_part_number",
          class: "primary customwhite--text",
        },
        {
          text: "Part Description",
          value: "bo_part_description",
          class: "primary customwhite--text",
        },
        { text: "Qty", value: "bo_qty", class: "primary customwhite--text" },
        {
          text: "Actions",
          value: "actions",
          class: "primary customwhite--text",
          sortable: false,
        },
      ],
      headersWarranty: [
        {
          text: "Serial No",
          align: "start",
          value: "serial_number",
          class: "primary customwhite--text",
        },
        {
          text: "Asset Tag",
          value: "asset_tag",
          class: "primary customwhite--text",
        },
        {
          text: "Problem Description",
          value: "problem_description",
          class: "primary customwhite--text",
        },
        {
          text: "Tracking",
          value: "return_tracking_number",
          class: "primary customwhite--text",
        },
        {
          text: "Return Carrier",
          value: "return_carrier",
          class: "primary customwhite--text",
        },
        {
          text: "Warranty",
          value: "warranty_status",
          class: "primary customwhite--text",
        },
        {
          text: "Actions",
          value: "actions",
          class: "primary customwhite--text",
          sortable: false,
        },
      ],
      projectddl: false,
      returnPartDesc: "",
      showEditAdd: false,
      buildToQty: "",
      editSerialNum: "",
      editAssetTag: "",
      showCustomerDialog: false,
      showShipAddress: false,
      showPartsItem: false,
      showReturnAddress: false,
      sameAsShip: false,
      stepElement: 1,
      date: "",
      flex_field_Att: "",
      flex_value: "",
      showReturnToDialog: false,
      newHire: false,
      showAddress: false,
      userId: EncryptUtility.localStorageDecrypt("userID"),
      projectList: [],
      sameAsCust: false,
      disableProject: false,
      disableShippingSection: false,
      disableNotes: false,
      returnCarrierList: [],
      shipCarrierList: [],
      address: "",
      showexpIcon: false,
      showPartRetDetails: false,
      showPartShipDetails: false,
      buildPartNumList: false,
      disableBuildSave: true,
      showBuildIcon: false,
      disableShip2: false,
      disableReturn: false,
      disableShip: false,
      showFlexDialog: false,
      disableCustomer: false,
      disableSalesDetails: false,
      actionList: [],
      favouriteList: [],
      pageUrl: "",
      edit_line_num: "",
      subPageUrl: "",
      refreshDialog: false,
      isEditing: false,
      appPageName: "",
      backSlashParent: true,
      mainPage: "",
      businessList: [],
      backSlash: true,
      subPage: "",
      editPartDesc: "",
      pageName: "",
      customerList: [],
      shipList: [],
      orderTypeList: [],
      paymentList: [],
      shipPartKey: "",
      showShipToDialog: false,
      salesFormValid: false,
      showEditRetAdd: false,
      shipPartName: "",
      shipPartDesc: "",
      panel: [0, 1, 2, 3, 4],
      editListFlex: false,
      returnWareList: [],
      showEditBuild: false,
      disableRMA: true,
      buildOrderEditShow: false,
      build_id: 0,
      returnData: [],
      disableSODetails: false,
      disableBusinessDep: true,
      listHeader: [
        {
          text: "Line#",
          align: "start",
          value: "line_number",
          class: "primary customwhite--text",
        },
        {
          text: "Line Type",
          value: "order_line_text",
          class: "primary customwhite--text",
        },
        {
          text: "Part Number",
          value: "sh_part_number",
          class: "primary customwhite--text",
        },
        {
          text: " Return Part Number",
          value: "return_part_number",
          class: "primary customwhite--text",
        },
        {
          text: "Outbound Warehouse",
          value: "sh_from_warehouse",
          class: "primary customwhite--text",
        },
        {
          text: "Inbound Warehouse",
          value: "return_to_warehouse",
          class: "primary customwhite--text",
        },
        {
          text: "Shipment Order Qty",
          value: "sh_quantity",
          class: "primary customwhite--text",
        },
        {
          text: "Shipment Price ($)",
          value: "sh_price",
          class: "primary customwhite--text",
        },
        {
          text: "Return Order Qty",
          value: "return_quantity",
          class: "primary customwhite--text",
        },
        {
          text: "Return Price ($)",
          value: "return_price",
          class: "primary customwhite--text",
        },

        {
          text: "Actions",
          value: "actions",
          class: "primary customwhite--text",
          sortable: false,
        },
      ],
      totalRecords: 0,
      listItemList: [],
      flexHeader: [
        {
          text: "Flex Attribute",
          align: "start",
          value: "flex_code",
          class: "primary customwhite--text",
        },
        {
          text: "Flex Value",
          value: "flex_data",
          class: "primary customwhite--text",
        },
        {
          text: "Actions",
          value: "actions",
          class: "primary customwhite--text",
          sortable: false,
        },
      ],
      sequence: 0,
      showPartDetails: false,
      serial_num: "",
      asset_tag: "",
      warr_status: "N",
      problem_desc: "",
      tracking_num: "",
      temp_waybill_id: 1,
      buildObj: {},
      temp_so_id: 0,
      //SOJSON
      salesOrderJson: {
        request_number: "",
        step_number: 0,
        proj_id: "",
        bu_id: "",
        CustomerInfo: [
          {
            title: "",
            first: "",
            last: "",
            cust_id: "",
            custno: "",
            company: "",
            address1: "",
            address2: "",
            city: "",
            state: "",
            zip: "",
            country: "",
            phone1: "",
            phone2: "",
            cell: "",
            fax: "",
            email: "",
          },
        ],
        shipment_address: [
          {
            sh_title: "",
            sh_first: "",
            sh_last: "",
            sh_custno: "",
            sh_company: "",
            sh_address1: "",
            sh_address2: "",
            sh_city: "",
            sh_state: "",
            sh_zip: "",
            sh_country: "",
            sh_phone1: "",
            sh_phone2: "",
            sh_cell: "",
            sh_fax: "",
            sh_email: "",
            sh_cust_id: "",
          },
        ],
        return_address: [
          {
            rt_title: "",
            rt_first: "",
            rt_last: "",
            rt_comp_id: "",
            rt_custno: "",
            rt_company: "",
            rt_address1: "",
            rt_address2: "",
            rt_city: "",
            rt_state: "",
            rt_zip: "",
            rt_country: "",
            rt_phone1: "",
            rt_phone2: "",
            rt_cell: "",
            rt_fax: "",
            rt_email: "",
          },
        ],
        auto_generate_order_number: false,
        order_number: "",
        order_status: "",
        order_type_id: "",
        order_type: "",
        rma: "",
        ordered_date: "",
        customer_po: "",
        customer_number: "",
        carrierLevlInfo: false,
        customer_order_number: "",
        requested_ship_date: "",
        pay_term_id: "",
        fob: "",
        ritm: "",
        shipment_terms: "",
        employee_name: "",
        employee_id: "",
        new_hire: "",
        new_hire_date: "",
        shipping_priority: "",
        return_shipping_priority: "",
        signature_required: false,
        saturday_delivery: false,
        problem_description: "",
        so_notes: "",
        order_notes: "",
        flex_field: [],
        order_line: [],
      },
      //TEMPJSON
      temp_order_line: {
        sequence_number: "",
        order_line_type: "",
        line_number: "",
        sh_part_id: "",
        sh_part_number: "",
        sh_part_description: "",
        sh_from_warehouse_id: "",
        sh_from_warehouse: "",
        sh_from_inventory_bucket: "",
        sh_quantity: "",
        sh_price: "0.00",
        build_orders: {
          build_order_notes: "",
          build_order: [],
        },
        return_part_id: "",
        return_part_number: "",
        return_part_description: "",
        return_to_warehouse_id: "",
        return_to_warehouse: "",
        return_to_inventory_bucket: "",
        return_quantity: "",
        return_price: "0.00",
        return_rma: "",
        expected_serial_number: [],
        line_comment: "",
        flex_field: [],
        // Add by Dev team for showing text
        order_line_text: "",
        showPartRet: "",
        showPartShip: "",
        showBuild: "",
        showSerial: "",
      },
    };
  },
  async created() {
    let projectData = await commonApiService.getProject("get", this.userId);
    if (projectData?.ProjectInfo) this.projectList = projectData.ProjectInfo;
    // ID for SO Draft
    if (this.$route?.params?.id) {
      this.route = this.$route.path.replace("/", "").split("/")[0];
    } else {
      this.route = this.$route.path.replace("/", "");
    }
    let data = await AddFavourite.getParentPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
    this.subPageUrl = data.subPageUrl;
    this.appPageName = data.appPageName;
  },
  //Refresh  Warning
  beforeMount() {
    window.addEventListener("beforeunload", this.preventNav);
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("beforeunload", this.preventNav);
    });
  },
  //Refresh to check if Router is changes
  beforeRouteLeave(to, from, next) {
    if (this.isEditing) {
      if (window.confirm("Leave without saving?")) {
        next();
      } else {
        return false;
      }
    }
    next();
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.salesOrderJson.ordered_date
        ? moment(String(this.salesOrderJson.ordered_date)).format("MM/DD/YYYY")
        : moment(String(new Date())).format("MM/DD/YYYY");
    },
    shipcomputeDateFormattedMomentjs() {
      return this.salesOrderJson.requested_ship_date ? moment(String(this.salesOrderJson.requested_ship_date)).format("MM/DD/YYYY") : "";
    },
    newHireComputedDateFormattedMomentjs() {
      return this.salesOrderJson.new_hire_date ? moment(this.salesOrderJson.new_hire_date).format("MM/DD/YYYY") : "";
    },
  },
  async mounted() {
    //this is when we rediect from your Draft order to sales order Add Page
    if (this.$route?.params?.id) {
      // To get the id frm router
      let so_id = atob(this.$route.params.id);
      this.temp_so_id = so_id;
      this.disableProject = true;
      this.disableBusinessDep = false;
      this.disable_Step6 = true;
      //to get draft ofder JSON
      let orderDraftData = await salesService.getOrderDraftById("get", this.userId, so_id);
      this.draftData = orderDraftData[0]?.OJson;
      if (this.draftData) {
        this.salesOrderJson = JSON.parse(this.draftData);
        this.stepElement = this.salesOrderJson.step_number;
        let projObj = this.projectList?.filter((x) => x.ProjKey == this.salesOrderJson.proj_id);
        this.projectName = projObj[0]?.Project;
        // To show Customer Address
        this.shipSearchKey = this.salesOrderJson.shipment_address[0].sh_company;
        this.returnSearchKey = this.salesOrderJson.return_address[0].rt_company;
        this.custSearchKey = this.salesOrderJson.CustomerInfo[0]?.company;
        this.disableCustomer = true;
        if (this.salesOrderJson.step_number > 2) {
          this.showAddress = true;
        }
        // To show ship and return Address
        if (this.salesOrderJson.step_number > 3) {
          this.disableShip2 = true;
          this.disableReturn = true;
          this.showShipAddress = true;
          this.showReturnAddress = true;
        }
        if (this.salesOrderJson.step_number > 3) {
          // To get buinsess List
          let businessObj = {
            UserId: this.userId,
            ProjKey: this.salesOrderJson.proj_id,
            BuKey: 0, // Do not Delete
            IncludeEmptyBU: 0, // Do not Delete
            WithinSameLocation: 1, // Do not Delete
            loca_key: 0,
          };
          this.getBussinessData(businessObj);
          this.temp_order_line = JSON.parse(JSON.stringify(this.salesOrderJson.order_line));
          // to get Order Type and payment Terms
          let OrderType = {
            UserId: this.userId,
            bu_key: this.salesOrderJson.bu_id,
            pass: 1, // Do not Delete
          };
          this.getOrdTypeData(OrderType);
          // Line Type API Call
          this.getLineTypeData();
        }
        this.totalRecords = this.salesOrderJson.order_line.length;

        // if step number is greater than 5 we will have to load Shipping dropdown as well
        if (this.salesOrderJson.step_number > 4) {
          // To get shipping Dropdown Details
          let carrierObj = {
            UserId: this.userId,
            bu_key: this.salesOrderJson.bu_id,
            custpo: this.salesOrderJson.customer_po,
            order_type: this.salesOrderJson.order_type_id,
          };
          this.getCarrierData(carrierObj);
        }
      }
      if (this.salesOrderJson.step_number > 6) {
        // To enable sales buttons Dropdown Details
        this.disableSubmit = false;
      }
    }
  },
  methods: {
    //to check if its a number of not
    isNumber(event, loc) {
      if (loc == "number") {
        let value = utility.isNumber(event)
        return value
      }
      else {
        let value = utility.isNumberWithPeriod(event)
        return value
      }
    },
    // iso Date Format
    isIsoDate(str) {
      if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
      const d = new Date(str);
      return d instanceof Date && !isNaN(d.getTime()) && d.toISOString() === str; // valid date 
    },

    // Scroll Check method
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    // To go to the top
    toTop() {
      this.$vuetify.goTo(0);
    },
    // To clean part number once its click on cancel
    clearPartShip() {
      this.$refs?.PartShipForm?.resetValidation()
      this.shipPartName = "";
      this.showBuildIcon = false;
      this.showStockStatus = false;
      this.showexpIcon = false;
      this.shipPartDesc = "";
      this.temp_order_line.sh_price = "0.00";
      this.temp_order_line.sh_quantity = "";
      this.temp_order_line.sh_from_warehouse_id = "";
      this.showPartDetails = false;
      this.shipWareList = [];
      this.disableQty = false;
      this.showBuildExpand = false;
      this.temp_order_line.sh_from_warehouse = "";
      this.temp_order_line.sh_from_inventory_bucket = "";
      this.temp_order_line.sh_part_id = "";
    },
    // To get the Data of business DropDown
    async getBussinessData(businessObject) {
      let businessData = await commonApiService.postBusinessData("post", businessObject);
      this.businessList = businessData?.map((x) => ({
        bu_id: x.Bu_id,
        business: x.Business,
        bu_payterm: x.pterms,
      }));
      let busObj = this.businessList?.filter((x) => x.bu_id == this.salesOrderJson.bu_id);
      this.businessName = busObj[0]?.business;
    },
    //to get order type Data
    async getOrdTypeData(object) {
      let orderTypeData = await salesService.postOrderTypeData("post", object);
      if (orderTypeData?.OrderTypes) {
        this.notRequired = true;
        this.orderTypeList = orderTypeData.OrderTypes?.map((x) => ({
          ord_code: x.ord_code,
          ordCodedesc: x.ordCodedesc,
        }));
      } else {
        this.notRequired = false;
        this.disableAuto = false;
      }
      // Payment trype Dropdown
      if (orderTypeData?.PaymentTerms) {
        this.paymentList = orderTypeData.PaymentTerms?.map((x) => ({
          payterm_id: x.payterm_id,
          payterm_desc: x.payterm_desc,
        }));
        let buId_t = object?.bu_key;
        if (!this.$route?.params?.id) {
          if (buId_t > 0) {
            let busObj_t = this.businessList?.filter((x) => x.bu_id == buId_t);
            this.salesOrderJson.pay_term_id = busObj_t[0]?.bu_payterm;
          }
        }
      }
    },
    // Get Line Type Data Function
    async getLineTypeData() {
      let LineTypeData = await salesService.getOrderTypeData("get", this.userId, this.salesOrderJson.proj_id, 0);
      if (LineTypeData?.LineTypeInfo) {
        this.LineTypeList = LineTypeData.LineTypeInfo?.map((x) => ({
          so_type_id: x.sotype_id,
          so_desc: x.so_desc,
          so_type: x.so_type,
          to_ship: x.to_ship,
          to_return: x.to_return,
          to_disable_quantity: x.to_disable_quantity,
          to_disable_ReturnSel: x.to_disable_ReturnSel,
        }));
      }
      if (this.$route?.params?.id) this.validateLineType();
      // TO be Called when Used or when to_return is 1
      let warrantyData = await salesService.getWarrentyData("get", this.userId);
      if (warrantyData?.WarrantyData) {
        this.warrantyList = warrantyData.WarrantyData?.map((x) => ({
          code: x.code,
          description: x.description,
        }));
      }
    },
    // To get ship Warehouse Common function
    async getShipWarehoueData(shipObject,loc) {
      let shipWarehouseList = await salesService.postShipWareData("post", shipObject);
      this.showBuildIcon = shipWarehouseList?.Result2[0]?.enable_build_sheet;
      this.showexpIcon = shipWarehouseList?.Result2[0]?.enable_expected_sn;

      // this.showBuildIcon = true // DO no Delete
      
      if (loc=="ship"&& shipWarehouseList?.ShipFromWare) {
        this.shipWareList = shipWarehouseList.ShipFromWare?.map((x) => ({
          ware: x.ware,
          ware_id: x.ware_id + "_" + x.bucket,
          bucket: x.bucket,
          from_warebuck: x.from_warebuck,
          mess_bit: x.mess_bit,
          aqty: x.aqty,
          show_text: x.show_text,
        }));
      }
      if (loc=="return" && shipWarehouseList?.ReturnToWare != "") {
        this.returnWareList = shipWarehouseList?.ReturnToWare?.map((x) => ({
          ware: x.ware,
          ware_id: x.ware_id + "_" + x.bucket,
          bucket: x.bucket,
          into_warebuck: x.into_warebuck,
        }));}
      
    },
    // Carrier Data for Return and SHip List
    async getCarrierData(carrierObj) {
      let carrierData = await salesService.postCarrierData("post", carrierObj);
      this.returnCarrierList = carrierData.InboundSLs;
      this.shipCarrierList = carrierData.OutboundSLs;
    },
    // Clear Part Return Items
    clearPartReturn() {
      this.$refs?.PartRecieveForm?.resetValidation()
      this.temp_order_line.return_quantity = "";
      this.temp_order_line.return_part_number = "";
      this.temp_order_line.return_part_description = "";
      this.temp_order_line.return_price = "0.00";
      this.temp_order_line.return_to_inventory_bucket = "";
      this.temp_order_line.return_to_warehouse = "";
      this.temp_order_line.return_to_warehouse_id = "";
      this.returnWareList = [];
      this.sameAsShip = false;
      this.expected_serial_number = [];

    },
    onChangeProject(id) {
      let businessObj = {
        UserId: this.userId,
        ProjKey: id,
        BuKey: 0, // Do not Delete
        IncludeEmptyBU: 0, // Do not Delete
        WithinSameLocation: 1, // Do not Delete
        loca_key: 0,
      };
      this.getBussinessData(businessObj);
    },
    // Pareent navgatin
    preventNav(event) {
      if (!this.isEditing) return;
      event.preventDefault();
      // Chrome requires returnValue to be set.
      event.returnValue = "";
    },
    //Yes button on the refresh popup click event
    refreshYesClick() {
      this.refreshDialog = false;
    },
    //No button on the refresh popup click event
    refreshNoClick() {
      this.refreshDialog = false;
    },
    // on Click Step 2
    async step2Clicked() {
      if (this.$refs.projectForm?.validate()) {
        let countData = await salesService.getCustomerCount("get", this.userId, "NA", this.salesOrderJson.proj_id);
        countData.Message == 0 ? (this.showShipToDialog = true) : (this.showShipToDialog = false);

        this.stepElement = 2; //Do Not Remove
        this.isEditing = true;
        this.disableProject = true;
        this.projectddl = true;
        this.sameAsCust = true;

        let projObj = this.projectList?.filter((x) => x.ProjKey == this.salesOrderJson.proj_id);
        this.projectName = projObj[0]?.Project;
        let returnData = await this.getReturnAddress(2);
        Object.assign(this.salesOrderJson.return_address[0], returnData.DefaultReturnAddress[0]);
        this.returnSearchKey = returnData.DefaultReturnAddress[0].rt_company;
        this.showReturnAddress = true;
      }
    },
    //Get Customer Data
    async getCustomerData() {
      if (this.custSearchKey) {
        this.custSearchKey = this.custSearchKey?.toUpperCase();
        if (this.custSearchKey.length > 2) {
          let obj = {
            UserId: this.userId,
            proj_key: this.salesOrderJson.proj_id,
            SearchStr: this.custSearchKey,
            pass: 1, // DO not Delte
          };
          let resp = await salesService.postCustomerData("post", obj, false, true);
          if (resp?.CustomerInfo) {
            this.customerList = resp.CustomerInfo?.map((x) => ({
              title: x.title,
              last: x.first + " " + x.last,
              cust_id: x.cust_id,
              custno: x.custno,
              company: x.company,
              address1: x.address1,
              address2: x.address2,
              city: x.city,
              state: x.state,
              zip: x.zip,
              country: x.country,
              phone1: x.phone1,
              phone2: x.phone2,
              cell: x.cell,
              email: x.email,
              fax: x.fax,
            }));

          }
        } else {
          this.clearCustAddressData();
        }
      }
    },
    //Clear Customer address data
    clearCustAddressData() {
      this.customerList = [];
      this.showAddress = false;
      this.salesOrderJson.CustomerInfo =
      {
        first: "",
        title: "",
        last: "",
        cust_id: "",
        custno: "",
        company: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
        country: "",
        phone1: "",
        phone2: "",
        cell: "",
        fax: "",
        email: "",
      }
    },
    // TO show Address
    showCustomer(item) {
      Object.assign(this.salesOrderJson.CustomerInfo[0], item);
      this.custSearchKey = item.company;
      this.customerList = [];
      this.showAddress = true;
    },
    // on thrid step
    async step3Clicked() {

      this.stepElement = 2; // Do not Delele
      this.disableProject = true;
      this.disableCustomer = true;
      this.showReturnAddress = true;
      this.sameAsCust = true;
      this.onChangeSameAsCust();
    },
    // To get the Ship
    async getShipData() {
      if (this.shipSearchKey) {
        this.shipSearchKey = this.shipSearchKey?.toUpperCase();
        if (this.shipSearchKey.length > 2) {
          let obj = {
            UserId: this.userId,
            proj_key: this.salesOrderJson.proj_id,
            SearchStr: this.shipSearchKey,
            pass: 2, // Do not Delete
          };
          this.showShipAddDDL = true;
          let resp = await salesService.postCustomerData("post", obj, false, true);
          if (resp?.CustomerInfo) {
            this.shipList = resp.CustomerInfo?.map((x) => ({
              sh_address1: x.sh_address1,
              sh_address2: x.sh_address2,
              sh_cell: x.sh_cell,
              sh_city: x.sh_city,
              sh_company: x.sh_company,
              sh_country: x.sh_country,
              sh_custno: x.sh_custno,
              sh_cust_id: x.sh_cust_id,
              sh_email: x.sh_email,
              sh_fax: x.sh_fax,
              sh_first: x.sh_first,
              sh_last: x.sh_last,
              sh_phone1: x.sh_phone1,
              sh_phone2: x.sh_phone2,
              sh_state: x.sh_state,
              sh_title: x.sh_title,
              sh_zip: x.sh_zip,
            }));
          }
        } else {
          this.clearShipAddressData();
        }
      }
    },
    //clear Ship address
    clearShipAddressData() {
      this.shipList = [];
      this.showShipAddress = false;
      this.sameAsCust = false;
    },
    // to show Address
    showShip(item) {
      if (item) {
        this.showShipAddDDL = false;
        this.showShipAddress = true;
        this.showEditAdd = true;

      }

      this.salesOrderJson.shipment_address[0] = JSON.parse(JSON.stringify(item));
      this.shipSearchKey = item.sh_company;
      this.shipList = [];
    },
    // Same as customer Checkbox
    onChangeSameAsCust() {
      this.showShipAddress = this.sameAsCust;
      this.shipSearchKey = this.custSearchKey;
      if (this.sameAsCust) {
        this.shipSearchKey = this.custSearchKey;
        if (this.salesOrderJson?.CustomerInfo) {
          this.salesOrderJson.shipment_address = this.salesOrderJson.CustomerInfo?.map((x) => ({
            sh_title: x.title,
            sh_first: x.first,
            sh_last: x.last,
            sh_company: x.company,
            sh_address1: x.address1,
            sh_address2: x.address2,
            sh_city: x.city,
            sh_custno: x.custno,
            sh_state: x.state,
            sh_zip: x.zip,
            sh_country: x.country,
            sh_phone1: x.phone1,
            sh_phone2: x.phone2,
            sh_cell: x.cell,
            sh_fax: x.fax,
            sh_email: x.email,
            sh_cust_id: x.cust_id,
          }));
        }
      } else {
        this.shipSearchKey = "";
        this.salesOrderJson.shipment_address[0] = [
          {
            sh_title: "",
            sh_first: "",
            sh_last: "",
            sh_company: "",
            sh_address1: "",
            sh_address2: "",
            sh_city: "",
            sh_state: "",
            sh_zip: "",
            sh_country: "",
            sh_phone1: "",
            sh_phone2: "",
            sh_cell: "",
            sh_fax: "",
            sh_email: "",
            sh_cust_id: "",
          },
        ];
      }
    },
    async getReturnAddress(pass) {
      let returnData = await salesService.getReturnAddData(
        "get",
        this.userId,
        this.salesOrderJson.proj_id,
        this.returnSearchKey,
        this.salesOrderJson.bu_id,
        "",
        false,
        true,
        pass
      );
      return returnData;
    },
    // To get the Return
    async getReturnData() {
      if (this.returnSearchKey) {
        this.returnSearchKey = this.returnSearchKey?.toUpperCase();
        if (this.returnSearchKey.length > 2) {
          this.showReturnAddDDL = true;
          let returnData = await this.getReturnAddress(1);
          if (returnData?.ReturnAddress)
            this.returnList = returnData.ReturnAddress?.map((x) => ({
              rt_address1: x.rt_address1,
              rt_address2: x.rt_address2,
              rt_cell: x.rt_cell,
              rt_city: x.rt_city,
              rt_company: x.rt_company,
              rt_country: x.rt_country,
              rt_custno: x.rt_custno,
              rt_comp_id: x.rt_comp_id,
              rt_email: x.rt_email,
              rt_fax: x.rt_fax,
              rt_first: x.rt_first,
              rt_last: x.rt_last,
              rt_phone1: x.rt_phone1,
              rt_phone2: x.rt_phone2,
              rt_state: x.rt_state,
              rt_title: x.rt_title,
              rt_zip: x.rt_zip,
            }));
        } else {
          this.clearReturnAddressData();
        }
      }
    },
    // to show Address
    showReturn(item) {
      if (item) {
        this.showReturnAddDDL = false;
        this.showReturnAddress = true;
      }
      Object.assign(this.salesOrderJson.return_address[0], item);
      this.returnSearchKey = item.rt_company;
      this.returnList = [];
    },
    //clear Ship address
    clearReturnAddressData() {
      this.returnList = [];
      this.showReturnAddress = false;

    },
    // on 4th step
    async step4Clicked() {
      this.showEditAdd = false;
      this.showEditReturnToDialog = false;
      this.showShipAddDDL = false;
      this.showReturnAddDDL = false;
      if (!this.showReturnAddress) {
        this.returnSearchKey = "";
      }
      if (!this.showShipAddress) {
        this.shipSearchKey = "";
      }
      this.stepElement = 3; // Do not Delete
      this.disableProject = true;
      this.disableCustomer = true;
      this.disableShip2 = true;
      this.disableReturn = true;
    },
    // on change business
    async onChangeBusiness(business) {
      let busObj = this.businessList?.filter((x) => x.bu_id == business);
      this.businessName = busObj[0]?.business;
      this.disableBusinessDep = false;
      let businessObj = {
        UserId: this.userId,
        bu_key: business,
        pass: 1, // Do not Delete
        loca_key: 0,
      };
      this.getOrdTypeData(businessObj);
    },
    // On Step 5
    async step5Clicked() {
      if (this.$refs.salesOrderForm?.validate()) {
        this.salesFormValid = true;
        this.stepElement = 4; // This is Hard Coded This is Step Key
        this.disableProject = true;
        this.disableCustomer = true;
        this.disableShip2 = true;
        this.disable_Step6 = true;
        this.disableReturn = true;
        this.disableSalesDetails = true;
        this.salesOrderJson.step_number = 4;
        // this.salesOrderJson.ordered_date =utility.convertLocalToUTC(this.salesOrderJson.ordered_date)
        // this.salesOrderJson.requested_ship_date = utility.convertLocalToUTC(this.salesOrderJson.requested_ship_date);
        // this.salesOrderJson.new_hire_date =  utility.convertLocalToUTC(this.salesOrderJson.new_hire_date);
        // console.log( "Ordered Date",this.salesOrderJson.ordered_date)
        // console.log( "req  Date",this.salesOrderJson.requested_ship_date)
        // console.log( "New hire Date",this.salesOrderJson.new_hire_date)
        let orderObj = this.orderTypeList?.filter((x) => x.ord_code == this.salesOrderJson.order_type_id);
        this.salesOrderJson.order_type_id = orderObj[0]?.ord_code;
        this.salesOrderJson.order_type = orderObj[0]?.ordCodedesc;

        // Temp save
        this.postTempSO(this.salesOrderJson);
      }
    },
    // On Step 6
    async step6Clicked() {
      this.validateLineType();
      this.stepElement = 5; // This is hard COde
      this.disableProject = true;
      this.disableCustomer = true;
      this.disableShip2 = true;
      this.disableReturn = true;
      this.disableSalesDetails = true;
      let carrierObj = {
        UserId: this.userId,
        bu_key: this.salesOrderJson.bu_id,
        custpo: this.salesOrderJson.customer_po,
        order_type: this.salesOrderJson.order_type_id,
      };
      this.getCarrierData(carrierObj);
      this.salesOrderJson.step_number = 5;
      // Temp save
      this.postTempSO(this.salesOrderJson);
    },
    // on Step 7
    async step7Clicked() {
      if (this.$refs.CarrierLevlInfoForm?.validate()) {
        this.stepElement = 6; // this is hard coded
        this.disableProject = true;
        this.disableCustomer = true;
        this.disableShip2 = true;
        this.disableReturn = true;
        this.disableSalesDetails = true;
        this.disableShip = true;
        this.disableSubmit = false;
        this.disableShippingSection = true;
        this.salesOrderJson.step_number = 6;
        // Temp save
        this.postTempSO(this.salesOrderJson);
      }
    },
    // on Step 8
    async step8Clicked() {
      this.stepElement = 7; // this is hard coded
      this.disableProject = true;
      this.disableSubmit = false;
      this.disableCustomer = true;
      this.disableShip2 = true;
      this.disableReturn = true;
      this.disableShippingSection = true;
      this.disableSalesDetails = true;
      this.disableShip = true;
      this.disableNotes = true;
      this.salesOrderJson.step_number = 7;
      this.postTempSO(this.salesOrderJson);
    },
    // Line  Item
    // on Click Add List
    onClickAddList() {
      this.clearPartItem();
      this.getLineTypeData();
      this.showPartsItem = true;
    },
    onChangeShipping() {
      this.salesOrderJson.signature_required = false
      this.salesOrderJson.saturday_delivery = false;
      let CarrierFlags = this.shipCarrierList?.filter((x) => x.sl_id == this.salesOrderJson.shipping_priority);
      this.disableSignature = CarrierFlags[0].sig_required;
      this.disableSatDel = CarrierFlags[0].sat_delivery;
    },
    // On Change Line type
    OnChangeLineType(value) {
      value == 1 || value == 8 ? this.panel = [0] : this.panel = [0, 1];
      this.disableLine = true;
      let obj = this.LineTypeList?.filter((x) => x.so_type_id == value);
      this.showPartShip = obj[0].to_ship == 1 ? true : false;
      this.showPartRet = obj[0].to_return == 1 ? true : false;
      this.showFlex = true;
      this.temp_order_line.order_line_text = obj[0].so_desc;
      this.temp_order_line.showPartShip = this.showPartShip;
      this.temp_order_line.showPartRet = this.showPartRet;
      this.disableRetQty = obj[0].to_disable_quantity == 1 ? true : false;
      this.disablePartRecieve = obj[0].to_disable_ReturnSel == 1 ? true : false;
      // Default Value 1 for CR and EX Line Types
      if (value == 2 || value == 6) {
        this.temp_order_line.sh_quantity = 1;
      }
      // if part return is true then only return rma gets filled otherwise its empty
      if (this.showPartRet) this.temp_order_line.return_rma = this.salesOrderJson.rma;
      else this.temp_order_line.return_rma = "";
    },
    async validateSO(item) {
      if (item) {
        let resp = await salesService.validateSONumber("get", this.userId, this.salesOrderJson.proj_id, item);
        if (resp[0]?.OrdNum) {
          this.disableSODetails = false;
          this.disableRMA = false;
          this.disableSubmit = false;
        } else {
          this.disableSODetails = true;
          this.disableRMA = true;
        }
      }
    },
    //Get Ship  Data
    async partShipData() {
      this.partShipSearchKey = this.partShipSearchKey?.toUpperCase();
      this.clearPartShip()
      if (this.partShipSearchKey) {
        if (this.partShipSearchKey.length > 2) {
          this.showPartShipDetails = true;
          let shipDataObj = {
            UserId: this.userId,
            SearchStr: this.partShipSearchKey,
            BuKey: this.salesOrderJson.bu_id,
            order_type: this.salesOrderJson.order_type,
          };
          this.shipWareList = [];
          this.returnWareList = [];
          let shipDataSearch = await salesService.postShipSearch("post", shipDataObj, false, true);
          if (shipDataSearch?.Resultset) this.partResultList = shipDataSearch.Resultset;
          else this.clearPartShip()
        }
      }
    },
    // show Part Number Details
    async showPartDesc(item) {
      this.showPartDetails = true;
      this.partResultList = [];
      this.showBuildExpand = false;
      this.temp_order_line.build_orders.build_order = [];
      this.temp_order_line.sh_part_id = item.part_id;
      this.temp_order_line.sh_part_number = item.part_num;
      this.temp_order_line.sh_part_description = item.part_desc;
      this.shipPartName = item.part_num;
      this.partShipSearchKey = item.part_num;
      this.shipPartDesc = item.part_desc;
      this.shipPartKey = item.part_id;
      let shipWareObj = {
        part_num: item.part_num,
        order_type: this.salesOrderJson.order_type,
        sotype_key: this.temp_order_line.order_line_type,
        order_bu_key: this.salesOrderJson.bu_id,
        line_id: 0, //Do not Delete
        so_key: 0, //DO not Delete
        UserID: this.userId,
      };
      this.getShipWarehoueData(shipWareObj,"ship");
      if (this.disablePartRecieve) {
        this.partReturnSearchKey = this.partShipSearchKey;
        this.temp_order_line.return_part_number = this.temp_order_line.sh_part_number;
        this.temp_order_line.return_quantity = this.temp_order_line.sh_quantity;
        this.temp_order_line.return_part_description = this.temp_order_line.sh_part_description;
        this.temp_order_line.return_part_id = this.temp_order_line.sh_part_id;
      }
    },
    //ON Part Number to CHange
    onPartShipChange(warehouse_id) {
      let partShipObj = this.shipWareList?.filter((x) => x.ware_id == warehouse_id);
      this.temp_order_line.sh_from_inventory_bucket = partShipObj[0].bucket;
      this.temp_order_line.sh_from_warehouse = partShipObj[0].ware;
      this.temp_order_line.sh_from_warehouse_id = partShipObj[0].ware_id;
      this.showStockStatus = partShipObj[0].mess_bit;
      this.stockMessage = partShipObj[0].show_text;
    },
    // On part Number to Return
    onPartReturnChange(warehouse_id) {
      let returnShipObj = this.returnWareList?.filter((x) => x.ware_id == warehouse_id);
      this.temp_order_line.return_to_inventory_bucket = returnShipObj[0].bucket;
      this.temp_order_line.return_to_warehouse = returnShipObj[0].ware;
      this.temp_order_line.return_to_warehouse_id = returnShipObj[0].ware_id;
    },
    //Part Number For Build Order
    async buildPartNum() {
      if (this.partBuildPartNum) {
        this.partBuildPartNum = this.partBuildPartNum?.toUpperCase();
        if (this.partBuildPartNum.length > 2) {
          this.buildPartNumList = true;
          let shipDataObj = {
            UserId: this.userId,
            SearchStr: this.partBuildPartNum,
            BuKey: this.salesOrderJson.bu_id,
            order_part_key: this.shipPartKey,
          };
          let buildorderListData = await salesService.postBuildData("post", shipDataObj, false, true);
          if (buildorderListData?.Resultset) {
            this.buildPartResultList = buildorderListData.Resultset;
          }
          else {
            this.partBuildPartNum = ""
          }
        }
      }
    },
    // Show Bulilf Part Number Deatils in Dropdown
    showBuildPartDesc(item) {
      this.disableBuildSave = false;
      this.partBuildPartNum = item.part_num;
      this.buildPartResultList = [];
      this.buildPartId = item.part_id;
      this.buildObj = item;
    },
    // on Save Build Part Number
    onClickSaveBuild(buildToQty) {
      if (this.$refs.BuildForm?.validate()) {
        this.disableBuildSave = true;
        this.build_id = this.build_id + 1;
        let newObj = {
          build_id: this.build_id,
          bo_qty: buildToQty,
          bo_part_id: this.buildObj.part_id,
          bo_part_description: this.buildObj.part_desc,
          bo_part_number: this.buildObj.part_num,
        };
        this.temp_order_line.build_orders.build_order.push(newObj);
        this.part_num = "";
        this.selectedbuildPart = "";
        this.buildToQty = "";
        this.partBuildPartNum = "";
        this.$refs.BuildForm?.resetValidation();
        this.$refs.BuildForm?.reset();
        this.temp_order_line.sh_quantity = 1;
        this.temp_order_line.return_quantity = 1;
        this.disableQty = true;
      }
    },
    // on Click open the Dialog Dialog of build order tables
    OnClickEditBuild(item, buildOrderEditShow) {
      if (buildOrderEditShow == "buildOrderEditShow") {
        {
          this.editListFlex = false;
          this.editWaybillShow = false;
          this.showEditBuild = true;
          this.editPartQty = item.bo_qty;
          this.editPartNum = item.bo_part_number;
          this.editPartDesc = item.bo_part_description;
          this.build_id = item.build_id;
          this.buildRow = true;
        }
      } else if (buildOrderEditShow == "editWaybillShow") {
        this.buildRow = false;
        this.editListFlex = false;
        this.editWaybillShow = true;
        this.showEditBuild = true;
        this.editSerialNum = item.serial_number;
        this.editAssetTag = item.asset_tag;
        this.sequence_number = item.sequence_number;
        this.editProblemDesc = item.problem_description;
        this.editWarrantyStatus = item.warranty_status;
        this.editTrack = item.return_tracking_number;
        this.editReturn_carrier = item.return_carrier;
        this.temp_waybill_id = item.temp_waybill_id;
      } else if (buildOrderEditShow == "editListFlex") {
        this.buildRow = false;
        this.editListFlex = true;
        this.editWaybillShow = false;
        this.showEditBuild = true;
        this.sequence_number = item.temp_flex_id;
        this.list_flex_field_Att = item.flex_code;
        this.list_flex_value = item.flex_data;
      }
    },
    // TO Edit the Build order Update Button
    onClickUpdateBuild(editPartQty, editPartDesc) {
      this.disableBuildSave = true;
      if (this.$refs.EditBuildForm?.validate()) {
        this.temp_order_line.build_orders.build_order = this.temp_order_line.build_orders.build_order?.map((p) =>
          p.build_id == this.build_id ? { ...p, bo_qty: editPartQty, bo_part_description: editPartDesc } : p
        );
        this.showEditBuild = false;
      }
    },
    // To delete Build order
    deleteBuild() {
      this.disableBuildSave = true;
      this.temp_order_line.build_orders.build_order = this.temp_order_line.build_orders.build_order?.filter((x) => x.build_id !== this.build_id);
      if (!this.temp_order_line.build_orders.build_order.length) this.disableQty = false;
      this.showEditBuild = false;
      this.deleteBuildDialog = false;
    },
    // return to ship
    async returnPartData() {
      this.temp_order_line.return_quantity = this.temp_order_line.sh_quantity;
      this.partReturnSearchKey = this.partReturnSearchKey?.toUpperCase();
      this.clearPartReturn()
      if (this.partReturnSearchKey) {
        if (this.partReturnSearchKey?.length > 2) {
          this.showPartRetDetails = true;
          let returnSerachObj = {
            UserId: this.userId,
            SearchStr: this.partReturnSearchKey,
            BuKey: this.salesOrderJson.bu_id,
            order_type: this.salesOrderJson.order_type,
            sotype_key: this.temp_order_line.order_line_type,
            ship_part_key: this.shipPartKey,
            line_key: 0, // Do not Delete
          };
          this.returnWareList = [];
          let returnSearchData = await salesService.postReturnSearch("post", returnSerachObj, false, true);
          if (returnSearchData?.Resultset) this.returnResultList = returnSearchData?.Resultset;
          else this.clearPartReturn()
        }
      }
    },
    // Return Part Desc
    async showReturnPartDesc(item) {
      this.returnResultList = [];
      this.partReturnSearchKey = item.part_num;
      this.temp_order_line.return_quantity = this.temp_order_line.sh_quantity
      this.temp_order_line.return_part_id = item.part_id;
      this.temp_order_line.return_part_number = item.part_num;
      this.temp_order_line.return_part_description = item.part_desc;
      let RetrunWareObj = {
        part_num: item.part_num,
        order_type: this.salesOrderJson.order_type,
        sotype_key: this.temp_order_line.order_line_type,
        order_bu_key: this.salesOrderJson.bu_id,
        line_id: 0, //Do not Delete
        so_key: 0, //DO not Delete
        UserID: this.userId,
      };
      this.getShipWarehoueData(RetrunWareObj,"return");
    },
    // on Save Waybill
    onClickSerialAdd(serialNum, assetTag, probDesc, selectedWarrantyStatus, trackNum, return_carrier) {
      if (this.$refs.ExpectedSrForm?.validate()) {
        if (this.temp_order_line.expected_serial_number.length <= 0) {
          let expected_ord_seq =
            this.temp_order_line.expected_serial_number.length == undefined || this.temp_order_line.expected_serial_number.length == null
              ? 0
              : this.temp_order_line.expected_serial_number.length;
          this.temp_waybill_id = this.temp_waybill_id + 1;
          let obj = {
            temp_waybill_id: this.temp_waybill_id,
            sequence_number: expected_ord_seq + 1,
            serial_number: serialNum,
            asset_tag: assetTag,
            problem_description: probDesc,
            warranty_status: selectedWarrantyStatus,
            return_tracking_number: trackNum,
            return_carrier: return_carrier,
          };
          this.temp_order_line.expected_serial_number.push(obj);
          this.serial_num = "";
          this.asset_tag = "";
          this.problem_desc = "";
          this.warr_status = "N";
          this.tracking_num = "";
          this.return_carrier = "";
          this.$refs.ExpectedSrForm?.resetValidation();
        } else {
          let validSerial = {};
          if (!serialNum) {
            validSerial = this.temp_order_line.expected_serial_number?.find((x) => x.return_tracking_number == trackNum);
          } else {
            validSerial = this.temp_order_line.expected_serial_number?.find((x) => x.serial_number == serialNum);
          }
          if (!trackNum && !validSerial) validSerial = this.temp_order_line.expected_serial_number?.find((x) => x.serial_number == serialNum);
          else {
            validSerial = this.temp_order_line.expected_serial_number?.find(
              (x) => x.return_tracking_number == trackNum || x.serial_number == serialNum
            );
          }
          if (!validSerial) {
            let expected_ord_seq =
              this.temp_order_line.expected_serial_number.length == undefined || this.temp_order_line.expected_serial_number.length == null
                ? 0
                : this.temp_order_line.expected_serial_number.length;
            this.temp_waybill_id = this.temp_waybill_id + 1;
            let obj = {
              temp_waybill_id: this.temp_waybill_id,
              sequence_number: expected_ord_seq + 1,
              serial_number: serialNum,
              asset_tag: assetTag,
              problem_description: probDesc,
              warranty_status: selectedWarrantyStatus,
              return_tracking_number: trackNum,
              return_carrier: return_carrier,
              is_Added: true,
              is_Deleted: false,
              sobcnId: 0,
            };
            this.temp_order_line.expected_serial_number.push(obj);
            this.serial_num = "";
            this.asset_tag = "";
            this.problem_desc = "";
            this.warr_status = "N";
            this.tracking_num = "";
            this.return_carrier = "";
            this.$refs.ExpectedSrForm?.resetValidation();
          } else {
            if (validSerial?.serial_number) {
              let Alert = {
                type: "error",
                isShow: true,
                message: "The Serial number " + serialNum + " is already added in the order line",
              };
              this.$store.commit("ConfigModule/Alert", Alert);
              validSerial = "";
            } else {
              let Alert = {
                type: "error",
                isShow: true,
                message: "The Tracking number " + trackNum + " is already added in the order line",
              };
              this.$store.commit("ConfigModule/Alert", Alert);
              validSerial = "";
            }
          }
        }
      }
    },
    // Delete Waybill
    deleteWaybill() {
      this.temp_order_line.expected_serial_number = this.temp_order_line.expected_serial_number?.filter(
        (x) => x.temp_waybill_id !== this.temp_waybill_id
      );
      this.showEditBuild = false;
      this.deleteBuildDialog = false;
    },
    // Clear Part Item
    clearPartItem() {
      this.lineItemPanel = [0, 1];
      this.disableBuildSave = true;
      this.showPartShipDetails = false;
      this.buildPartNumList = false;
      this.showPartRetDetails = false;
      this.showPartDetails = false;
      this.disableQty = false;
      this.showBuildExpand = false;
      this.showStockStatus = false;
      this.disablePartRecieve = false;
      this.serial_num = "";
      this.asset_tag = "";
      this.problem_desc = "";
      this.warr_status = "N";
      this.tracking_num = "";
      this.return_carrier = "";
      this.stockMessage = "";
      this.temp_order_line = {
        sequence_number: "",
        order_line_type: "",
        line_number: "",
        sh_part_id: "",
        sh_part_number: "",
        sh_part_description: "",
        sh_from_warehouse_id: "",
        sh_from_warehouse: "",
        sh_from_inventory_bucket: "",
        sh_quantity: "",
        sh_price: "0.00",
        build_orders: {
          build_order_notes: "",
          build_order: [],
        },
        return_part_id: "",
        return_part_number: "",
        return_part_description: "",
        return_to_warehouse_id: "",
        return_to_warehouse: "",
        return_to_inventory_bucket: "",
        return_quantity: "",
        return_price: "0.00",
        expected_serial_number: [],
        line_comment: "",
        flex_field: [],
      };
      this.showLineDelete = false;
      this.showExpectedSr = false;
      this.sameAsShip = false;
      (this.shipPartName = ""), (this.partBuildPartNum = "");
      this.buildToQty = "";
      this.shipPartDesc = "";
      this.partShipSearchKey = "";
      this.partReturnSearchKey = "";
      this.serial_num = "";
      this.warranty_status = "";
      this.asset_tag = "";
      this.problem_desc = "";
      this.disableLine = false;
      this.showPartShip = false;
      this.showFlex = false;
      this.showPartRet = false;
      this.showexpIcon = false;
      this.showBuildIcon = false;
      this.showBuildExpand = false;
    },
    // Edit Line List
    onClickEditList(item) {
      this.temp_order_line.order_line_type == 1 || this.temp_order_line.order_line_type == 8 ? this.panel = [0] : this.panel = [0, 1];
      this.showPartDetails = true;
      this.showReturnDetails = true;
      this.shipPartName = item.sh_part_number;
      this.shipPartDesc = item.sh_part_description;
      this.showLineDelete = true;
      this.editLinePart = true;
      this.temp_order_line = JSON.parse(JSON.stringify(item));
      if (!item.sh_from_warehouse_id.includes("_") || !this.temp_order_line.return_to_warehouse_id.includes("_")) {
        this.temp_order_line.sh_from_warehouse_id = item.sh_from_warehouse_id.concat("_", item.sh_from_inventory_bucket);
        this.temp_order_line.return_to_warehouse_id = item.return_to_warehouse_id.concat("_", item.return_to_inventory_bucket);
      }
      this.showPartsItem = true;
      this.disableLine = true;
      this.showPartShip = item.showPartShip;
      this.showPartRet = item.showPartRet;
      this.showexpIcon = item.showSerial;
      this.showBuildIcon = item.showBuild;
      this.showFlex = true;
      this.edit_line_num = item.line_number;
      this.partShipSearchKey = item.sh_part_number;
      this.partReturnSearchKey = item.return_part_number;
      let shipWareObj = {
        part_num: item.sh_part_number,
        order_type: this.salesOrderJson.order_type,
        sotype_key: this.temp_order_line.order_line_type,
        order_bu_key: this.salesOrderJson.bu_id,
        line_id: 0, //Do not Delete
        so_key: 0, //DO not Delete
        UserID: this.userId,
      };
      this.getShipWarehoueData(shipWareObj,"ship");
      if(item.showPartRet == true){
        let returnWareObj = {
          part_num: item.return_part_number,
          order_type: this.salesOrderJson.order_type,
          sotype_key: this.temp_order_line.order_line_type,
          order_bu_key: this.salesOrderJson.bu_id,
          line_id: 0, //Do not Delete
          so_key: 0, //DO not Delete
          UserID: this.userId,
        };

        this.getShipWarehoueData(returnWareObj,"return")
      }

    },
    //onClick Delete
    onClickLineDelete() {
      this.deleteBuildDialog = true;
      this.deleteItem = true;
    },
    //Delete Line Item
    deleteLineItem() {
      this.clearPartItem();
      this.salesOrderJson.order_line = this.salesOrderJson.order_line?.filter((x) => x.line_number !== this.edit_line_num);
      this.deleteBuildDialog = false;
      this.showPartsItem = false;
      this.totalRecords = this.salesOrderJson.order_line.length;
    },
    // On Click Add Part List Item
    submitLineItems() {
      this.temp_order_line.showBuild = this.temp_order_line?.build_orders?.build_order.length > 0;
      this.temp_order_line.showSerial = this.temp_order_line?.expected_serial_number.length > 0;
      let order_line_num =
        this.salesOrderJson.order_line.length == null || this.salesOrderJson.order_line.length == undefined
          ? 0
          : this.salesOrderJson.order_line.length;
      if (this.showPartShip && this.showPartRet) {
        if (this.$refs.PartShipForm?.validate() && this.$refs.PartRecieveForm?.validate()) {
          this.temp_order_line.return_to_warehouse_id = this.temp_order_line?.return_to_warehouse_id.split("_")[0];
          this.temp_order_line.sh_from_warehouse_id = this.temp_order_line?.sh_from_warehouse_id.split("_")[0];
          this.temp_order_line.line_number = order_line_num + 1;
          this.temp_order_line.sequence_number = this.temp_order_line.line_number;
          this.salesOrderJson.order_line.push(this.temp_order_line);
          this.salesOrderJson["temp_order_line"] = this.temp_order_line
          this.showPartsItem = false;
        }
      } else if (this.showPartShip && !this.showPartRet) {
        if (this.$refs.PartShipForm?.validate()) {
          this.temp_order_line.return_to_warehouse_id = this.temp_order_line?.return_to_warehouse_id.split("_")[0];
          this.temp_order_line.sh_from_warehouse_id = this.temp_order_line?.sh_from_warehouse_id.split("_")[0];
          this.temp_order_line.line_number = order_line_num + 1;
          this.temp_order_line.sequence_number = this.temp_order_line.line_number;
          this.temp_order_line.return_quantity = "";
          this.salesOrderJson.order_line.push(this.temp_order_line);
          this.salesOrderJson["temp_order_line"] = this.temp_order_line
          this.showPartsItem = false;
        }
      } else if (!this.showPartShip && this.showPartRet) {
        if (this.$refs.PartRecieveForm?.validate()) {
          this.temp_order_line.return_to_warehouse_id = this.temp_order_line?.return_to_warehouse_id.split("_")[0];
          this.temp_order_line.sh_from_warehouse_id = this.temp_order_line?.sh_from_warehouse_id.split("_")[0];
          this.temp_order_line.line_number = order_line_num + 1;
          this.temp_order_line.sequence_number = this.temp_order_line.line_number;
          this.salesOrderJson["temp_order_line"] = this.temp_order_line
          this.salesOrderJson.order_line.push(this.temp_order_line);
          this.showPartsItem = false;
        }
      }
      this.totalRecords = this.salesOrderJson.order_line.length;
    },
    //Edit LIne Item
    editLineItem() {
      this.temp_order_line.showPartShip = this.showPartShip;
      this.temp_order_line.showPartRet = this.showPartRet;
      let temp_objIdx = this.salesOrderJson.order_line?.findIndex((v) => v.line_number === this.temp_order_line.line_number);
      if (!~temp_objIdx) {
        return;
      }
      let temp_obj = this.temp_order_line
      if (this.showPartShip && this.showPartRet) {
        if (this.$refs.PartShipForm?.validate() && this.$refs.PartRecieveForm?.validate()) {
          temp_obj.sh_part_number = this.temp_order_line.sh_part_number
          temp_obj.sh_part_description = this.temp_order_line.sh_part_description
          this.temp_order_line.return_to_warehouse_id = this.temp_order_line?.return_to_warehouse_id?.split("_")[0];
          this.temp_order_line.sh_from_warehouse_id = this.temp_order_line?.sh_from_warehouse_id?.split("_")[0];
          temp_obj.quantity =
            this.temp_order_line.sh_quantity != "" ? parseInt(this.temp_order_line.sh_quantity) : parseInt(this.temp_order_line.return_quantity)
          temp_obj.return_part_number = this.temp_order_line.return_part_number
          temp_obj.return_part_description = this.temp_order_line.return_part_description
          this.temp_order_line.return_to_warehouse_id = this.temp_order_line?.return_to_warehouse_id?.split("_")[0];
          this.temp_order_line.sh_from_warehouse_id = this.temp_order_line?.sh_from_warehouse_id?.split("_")[0];
          this.showPartsItem = false;
          this.salesOrderJson?.order_line?.splice(temp_objIdx, 1, temp_obj);
        }
      } else if (this.showPartShip && !this.showPartRet) {
        if (this.$refs.PartShipForm?.validate()) {
          temp_obj.sh_part_number = this.temp_order_line.sh_part_number
          temp_obj.sh_part_description = this.temp_order_line.sh_part_description
          temp_obj.quantity =
            this.temp_order_line.sh_quantity != "" ? parseInt(this.temp_order_line.sh_quantity) : parseInt(this.temp_order_line.return_quantity);
          temp_obj.Open_Shipments = temp_obj.quantity - temp_obj.Shipped;
          temp_obj.return_part_number = "";
          temp_obj.return_part_description = "";
          this.temp_order_line.return_to_warehouse_id = this.temp_order_line?.return_to_warehouse_id?.split("_")[0];
          this.temp_order_line.sh_from_warehouse_id = this.temp_order_line?.sh_from_warehouse_id?.split("_")[0];
          this.showPartsItem = false;
          this.salesOrderJson?.order_line?.splice(temp_objIdx, 1, temp_obj);
        }
      } else if (!this.showPartShip && this.showPartRet) {
        if (this.$refs.PartRecieveForm?.validate()) {
          temp_obj.return_part_number = this.temp_order_line.return_part_number
          temp_obj.return_part_description = this.temp_order_line.return_part_description
          temp_obj.quantity =
            this.temp_order_line.sh_quantity != "" ? parseInt(this.temp_order_line.sh_quantity) : parseInt(this.temp_order_line.return_quantity);
          temp_obj.Open_Receipts = temp_obj.quantity - temp_obj.Rcvd;
          temp_obj.sh_part_number = "";
          temp_obj.sh_part_description = "";
          this.temp_order_line.return_to_warehouse_id = this.temp_order_line?.return_to_warehouse_id?.split("_")[0];
          this.temp_order_line.sh_from_warehouse_id = this.temp_order_line?.sh_from_warehouse_id?.split("_")[0];
          this.showPartsItem = false;
          this.salesOrderJson?.order_line?.splice(temp_objIdx, 1, temp_obj);
        }
      }
    },
    //on Click Cancel Line items
    cancelLineItems() {
      this.clearPartItem();
      this.showPartsItem = false;
      this.editLinePart = true;
    },
    // On Click Cancel of flex dialog
    onClickFlexCancel() {
      this.showFlexDialog = false;
      this.flex_field_Att = "";
      this.flex_value = "";
    },
    // Flex Details
    flexDetails(item) {
      this.buildRow = false;
      this.editFlex = true;
      this.editListFlex = false;
      this.editWaybillShow = false;
      this.showEditBuild = true;
      this.sequence_number = item.temp_flex_id ? item.temp_flex_id : item.sequence_number;
      this.list_flex_field_Att = item.flex_code;
      this.list_flex_value = item.flex_data;
    },
    // On Add Flex
    onClickFlexAdd(flex_field_Att, flex_value) {
      if (this.$refs.flexForm?.validate()) {
        let order_line_num =
          this.salesOrderJson.order_line.length == null || this.salesOrderJson.order_line.length == undefined
            ? 0
            : this.salesOrderJson.order_line.length;
        // if show parts item is true that means this add flex is for line Item
        if (this.showPartsItem) {
          let flx_ord_seq =
            this.temp_order_line.flex_field.length == undefined || this.temp_order_line.flex_field.length == null
              ? 0
              : this.temp_order_line.flex_field.length;
          let obj = {
            temp_flex_id: flx_ord_seq + 1,
            sequence_number: order_line_num + 1,
            flex_code: flex_field_Att,
            flex_data: flex_value,
          };
          this.temp_order_line.flex_field.push(obj);
        }
        // this flex is for main flex
        else {
          let flxseq =
            this.salesOrderJson.flex_field.length == undefined || this.salesOrderJson.flex_field.length == null
              ? 0
              : this.salesOrderJson.flex_field.length;
          let obj = {
            sequence_number: flxseq + 1,
            flex_code: flex_field_Att,
            flex_data: flex_value,
          };
          this.salesOrderJson.flex_field.push(obj);
        }
        this.showFlexDialog = false;
        this.flex_field_Att = "";
        this.flex_value = "";
        this.$refs.flexForm?.resetValidation();
      }
    },
    // Update flex
    onClickUpdateFlex(list_flex_field_Att, list_flex_value) {
      {
        // if show parts item is true the it will fill json flex or else it will update line item's flex
        if (!this.showPartsItem) {
          if (this.$refs.EditOrderflexForm?.validate()) {
            this.salesOrderJson.flex_field = this.salesOrderJson.flex_field?.map((p) =>
              p.sequence_number == this.sequence_number
                ? {
                  ...p,
                  flex_code: list_flex_field_Att,
                  flex_data: list_flex_value,
                }
                : p
            );
            this.showEditBuild = false;
          }
        } else {
          if (this.$refs.EditflexForm?.validate()) {
            this.temp_order_line.flex_field = this.temp_order_line.flex_field?.map((p) =>
              p.temp_flex_id == this.sequence_number
                ? {
                  ...p,
                  flex_code: list_flex_field_Att,
                  flex_data: list_flex_value,
                }
                : p
            );
            this.showEditBuild = false;
          }
        }
      }
    },
    //  Delete Flex
    deleteFlex() {
      this.temp_order_line.flex_field = this.temp_order_line.flex_field?.filter((x) => x.temp_flex_id !== this.sequence_number);
      this.showEditBuild = false;
      this.deleteBuildDialog = false;
    },
    // Delete Flex which is ouside line Items
    deleteJSONFlex() {
      this.salesOrderJson.flex_field = this.salesOrderJson.flex_field.filter((x) => x.sequence_number !== this.sequence_number);
      this.showEditBuild = false;
      this.deleteBuildDialog = false;
    },
    // Update waybil
    onClickUpdateWaybill(editSerialNum, editAssetTag, editProblemDesc, editWarrantyStatus, editTrack, return_carrier) {
      this.temp_order_line.expected_serial_number = this.temp_order_line.expected_serial_number?.map((p) =>
        p.temp_waybill_id == this.temp_waybill_id
          ? {
            ...p,
            serial_number: editSerialNum,
            asset_tag: editAssetTag,
            problem_description: editProblemDesc,
            warranty_status: editWarrantyStatus,
            return_tracking_number: editTrack,
            return_carrier: return_carrier,
          }
          : p
      );
      this.showEditBuild = false;
    },
    // Same as Part to Recive
    onChangeSameAsShip() {
      if (this.sameAsShip) {
        this.partReturnSearchKey = this.partShipSearchKey;
        this.returnPartNum = this.shipPartName;
        this.returnPartDesc = this.shipPartDesc;
        this.temp_order_line.return_part_number = this.temp_order_line.sh_part_number;
        //this.temp_order_line.return_quantity = this.temp_order_line.sh_quantity
        //this.temp_order_line.return_price = this.temp_order_line.sh_price
        this.temp_order_line.return_part_description = this.temp_order_line.sh_part_description;
        // this.temp_order_line.return_to_warehouse_id = this.temp_order_line.sh_from_warehouse_id
        // this.temp_order_line.return_to_warehouse = this.temp_order_line.sh_from_warehouse
        this.temp_order_line.return_part_id = this.temp_order_line.sh_part_id;
        let RetrunWareObj = {
          part_num: this.temp_order_line.return_part_number,
          order_type: this.salesOrderJson.order_type,
          sotype_key: this.temp_order_line.order_line_type,
          order_bu_key: this.salesOrderJson.bu_id,
          line_id: 0, //Do not Delete
          so_key: 0, //DO not Delete
          UserID: this.userId,
        };
        this.getShipWarehoueData(RetrunWareObj,"return");
        
        let returnwareId = this.returnWareList.filter((x) => x.return_to_warehouse_id == this.temp_order_line.sh_from_warehouse_id);
        if (returnwareId?.return_to_warehouse_id) this.temp_order_line.return_to_warehouse_id = this.temp_order_line.sh_from_warehouse_id;
      } else {
        this.temp_order_line.return_part_number = "";
        //this.temp_order_line.return_quantity = ""
        this.temp_order_line.return_price = "0.00";
        this.temp_order_line.return_part_description = "";
        this.temp_order_line.return_to_warehouse_id = "";
        this.temp_order_line.return_part_id = "";
        this.partReturnSearchKey = "";

      }
    },
    //Getting the data back from Compoents based on val flag we will call the respsective APIs and but the address
    async addAddress(custObj, val) {
      if (val == "customer") {
        {
          let obj = {
            CuJson: JSON.stringify(custObj),
            pass: 1, //Do not remove
          };
          let res = await salesService.postAddCustomer("post", obj, true);
          Object.assign(this.salesOrderJson.CustomerInfo[0], custObj);
          this.salesOrderJson.CustomerInfo[0].cust_id = res.Result[0].Cust_Id; // DO not Remove
          this.salesOrderJson.CustomerInfo[0].custno = res.Result[0].custno;
          this.custSearchKey = this.salesOrderJson.CustomerInfo[0].company;
        }
        this.showCustomerDialog = false;
        this.showAddress = true;
      }
      // new ship is added
      else if (val == "ship") {
        let obj = {
          CuJson: JSON.stringify(custObj),
          pass: 2, // DO not Remove
        };
        let res = await salesService.postAddCustomer("post", obj, true);
        this.salesOrderJson.shipment_address[0].cust_id = res.Result[0].Cust_Id;
        this.salesOrderJson.shipment_address[0].sh_title = custObj.title;
        this.salesOrderJson.shipment_address[0].sh_custno = res.Result[0].custno;
        (this.salesOrderJson.shipment_address[0].sh_first = ""),
          (this.salesOrderJson.shipment_address[0].sh_last = custObj.first + " " + custObj.last),
          (this.salesOrderJson.shipment_address[0].sh_cust_id = custObj.cust_id),
          (this.salesOrderJson.shipment_address[0].sh_company = custObj.company),
          (this.salesOrderJson.shipment_address[0].sh_address1 = custObj.address1),
          (this.salesOrderJson.shipment_address[0].sh_address2 = custObj.address2),
          (this.salesOrderJson.shipment_address[0].sh_city = custObj.city),
          (this.salesOrderJson.shipment_address[0].sh_state = custObj.state),
          (this.salesOrderJson.shipment_address[0].sh_zip = custObj.zip),
          (this.salesOrderJson.shipment_address[0].sh_country = custObj.country),
          (this.salesOrderJson.shipment_address[0].sh_phone1 = custObj.phone1),
          (this.salesOrderJson.shipment_address[0].sh_phone2 = custObj.phone2),
          (this.salesOrderJson.shipment_address[0].sh_cell = custObj.cell),
          (this.salesOrderJson.shipment_address[0].sh_email = custObj.email),
          (this.salesOrderJson.shipment_address[0].sh_fax = custObj.fax);
        this.shipSearchKey = custObj.company;
        this.showShipToDialog = false;
        this.showShipAddress = true;
      }
      // if new return is addes
      else if (val == "return") {
        this.salesOrderJson.return_address[0].rt_title = custObj.title;
        (this.salesOrderJson.return_address[0].rt_first = ""),
          (this.salesOrderJson.return_address[0].rt_last = custObj.first + " " + custObj.last),
          (this.salesOrderJson.return_address[0].rt_comp_id = custObj.cust_id),
          (this.salesOrderJson.return_address[0].rt_custno = custObj.custno),
          (this.salesOrderJson.return_address[0].rt_company = custObj.company),
          (this.salesOrderJson.return_address[0].rt_address1 = custObj.address1),
          (this.salesOrderJson.return_address[0].rt_address2 = custObj.address2),
          (this.salesOrderJson.return_address[0].rt_city = custObj.city),
          (this.salesOrderJson.return_address[0].rt_state = custObj.state),
          (this.salesOrderJson.return_address[0].rt_zip = custObj.zip),
          (this.salesOrderJson.return_address[0].rt_country = custObj.country),
          (this.salesOrderJson.return_address[0].rt_phone1 = custObj.phone1),
          (this.salesOrderJson.return_address[0].rt_phone2 = custObj.phone2),
          (this.salesOrderJson.return_address[0].rt_cell = custObj.cell),
          (this.salesOrderJson.return_address[0].rt_email = custObj.email),
          (this.salesOrderJson.return_address[0].rt_fax = custObj.fax);
        this.showReturnToDialog = false;
        this.showReturnAddress = true;
        this.returnSearchKey = custObj.company;
      }
      // If user edits the address for just this order
      else if (val == "shipEdit") {
        this.salesOrderJson.shipment_address[0].sh_title = custObj.title;
        (this.salesOrderJson.shipment_address[0].sh_first = ""),
          (this.salesOrderJson.shipment_address[0].sh_last = custObj.first + " " + custObj.last),
          (this.salesOrderJson.shipment_address[0].sh_cust_id = custObj.cust_id),
          (this.salesOrderJson.shipment_address[0].sh_custno = custObj.custno),
          (this.salesOrderJson.shipment_address[0].sh_company = custObj.company),
          (this.salesOrderJson.shipment_address[0].sh_address1 = custObj.address1),
          (this.salesOrderJson.shipment_address[0].sh_address2 = custObj.address2),
          (this.salesOrderJson.shipment_address[0].sh_city = custObj.city),
          (this.salesOrderJson.shipment_address[0].sh_state = custObj.state),
          (this.salesOrderJson.shipment_address[0].sh_zip = custObj.zip),
          (this.salesOrderJson.shipment_address[0].sh_country = custObj.country),
          (this.salesOrderJson.shipment_address[0].sh_phone1 = custObj.phone1),
          (this.salesOrderJson.shipment_address[0].sh_phone2 = custObj.phone2),
          (this.salesOrderJson.shipment_address[0].sh_cell = custObj.cell),
          (this.salesOrderJson.shipment_address[0].sh_email = custObj.email),
          (this.salesOrderJson.shipment_address[0].sh_fax = custObj.fax);
        this.showEditShipToDialog = false;
        this.shipSearchKey = custObj.company;
      }
      // if Return edit is created from Address Comp
      else if (val == "returnEdit") {
        this.salesOrderJson.return_address[0].sh_title = custObj.title;
        (this.salesOrderJson.return_address[0].rt_first = ""),
          (this.salesOrderJson.return_address[0].rt_last = custObj.first + " " + custObj.last),
          (this.salesOrderJson.return_address[0].rt_cust_id = custObj.cust_id),
          (this.salesOrderJson.return_address[0].rt_custno = custObj.custno),
          (this.salesOrderJson.return_address[0].rt_company = custObj.company),
          (this.salesOrderJson.return_address[0].rt_address1 = custObj.address1),
          (this.salesOrderJson.return_address[0].rt_address2 = custObj.address2),
          (this.salesOrderJson.return_address[0].rt_city = custObj.city),
          (this.salesOrderJson.return_address[0].rt_state = custObj.state),
          (this.salesOrderJson.return_address[0].rt_zip = custObj.zip),
          (this.salesOrderJson.return_address[0].rt_country = custObj.country),
          (this.salesOrderJson.return_address[0].rt_phone1 = custObj.phone1),
          (this.salesOrderJson.return_address[0].rt_phone2 = custObj.phone2),
          (this.salesOrderJson.return_address[0].rt_cell = custObj.cell),
          (this.salesOrderJson.return_address[0].rt_email = custObj.email),
          (this.salesOrderJson.return_address[0].rt_fax = custObj.fax);
        this.showEditReturnToDialog = false;
        this.returnSearchKey = custObj.company;
      }
    },
    //Save Order details in temporary table
    async postSoTempUpsert() {
      this.disableDraftBtn = true;
      this.postTempSO(this.salesOrderJson);
    },
    // SO Draft API
    async postTempSO(salesOrderJson) {
      let obj_save = {
        UserId: this.userId,
        oJson: JSON.stringify(salesOrderJson),
        so_id: this.temp_so_id,
        isDraft: 1, // Hard Coded DO NoT delete
        project: this.projectName,
        business: this.businessName,
      };
      let saveOrder = await salesService.postSoTempUpsert("post", obj_save, true);
      if (saveOrder) {
        this.temp_so_id = saveOrder.Result[0].Temp_so_id;
        this.disableDraftBtn = false;
      }
    },
    // To submit the SO created by the user
    async postSoSubmit() {
      this.disableSubmitBtn = true;
      if (this.$refs.CarrierLevlInfoForm?.validate() && this.$refs.addressForm?.validate()) {
        let LoaderDialog = {
          visible: true,
          title: "Please Wait...",
        };
        this.clearCustAddressData()
        this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        this.salesOrderJson.ordered_date = utility.convertLocalToUTC(this.salesOrderJson.ordered_date)
        this.salesOrderJson.requested_ship_date = this.salesOrderJson?.requested_ship_date ?
          this.salesOrderJson.requested_ship_date : "";
        this.salesOrderJson.new_hire_date = this.salesOrderJson?.new_hire_date ?
          utility.convertLocalToUTC(this.salesOrderJson.new_hire_date) : "",
          this.salesOrderJson["draft_id"] = this.temp_so_id;
        let final_save = {
          UserId: this.userId,
          json: JSON.stringify(this.salesOrderJson),
        };
        this.salesOrderJson.ordered_date = this.salesOrderJson.ordered_date?.substr(0, 10);
        this.salesOrderJson.requested_ship_date = this.salesOrderJson.requested_ship_date?.substr(0, 10);
        this.salesOrderJson.new_hire_date = this.salesOrderJson.new_hire_date?.substr(0, 10);
        this.disableSubmitBtn = false;
        let saveOrder = await salesService.postSoSubmitUpsert("post", final_save, true);
        if (saveOrder == 400) {
          this.disableSubmitBtn = false;
        } else {
          this.isEditing = false;
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          this.$router.push({
            name: "salesOrderDetails",
            params: {
              id: btoa(saveOrder?.Result[0].so_id.toString()),
            },
          });
        }
      } else {
        this.disableSubmitBtn = false;

        if (!this.$refs.addressForm?.validate() && !this.$refs.CarrierLevlInfoForm?.validate()) {
          let invalidField = this.$refs.addressForm.$children?.find((e) => !e.valid);
          if (invalidField)
            invalidField.$el.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
          if (this.returnReq) this.disableReturn = false;
          else this.disableShip2 = false;
          this.disableShippingSection = false;
          this.disableSubmitBtn = false;
        } else if (!this.$refs.addressForm?.validate()) {
          let invalidField = this.$refs.addressForm.$children.find((e) => !e.valid);
          if (invalidField)
            invalidField.$el.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });

          if (this.returnReq) this.disableReturn = false;
          else this.disableShip2 = false;
        } else {
          this.disableShippingSection = false;
          let invalidField = this.$refs.CarrierLevlInfoForm.$children?.find((e) => !e.valid);
          if (invalidField)
            invalidField.$el.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
        }
      }
    },
    // to refresh the Page
    resetFunction() {
      this.showOrderedDate = false;
      this.showShipByDate = false;
      this.showEndDate = false;
      this.showNewHireDate = false
      this.$nextTick(() => {
        this.showOrderedDate = true;
        this.showShipByDate = true;
        this.showNewHireDate = true;
        this.salesOrderJson.ordered_date = null;
        this.salesOrderJson.requested_ship_date = null;
        this.salesOrderJson.new_hire_date = null;

      });
      this.sameAsCust = false;
      this.disableRMA = true;
      this.disable_Step6 = false;
      this.disableProject = false;
      this.disableCustomer = false;
      this.disableShip2 = false;
      this.disableReturn = false;
      this.disableShippingSection = false;
      this.disableSalesDetails = false;
      this.disableShip = false;
      this.disableNotes = false;
      this.returnSearchKey = "";
      this.custSearchKey = "";
      this.shipSearchKey = "";
      this.stepElement = 1;
      (this.salesOrderJson = {
        step_number: 1,
        request_number: "",
        proj_id: "",
        bu_id: "",
        CustomerInfo: [
          {
            title: "",
            first: "",
            last: "",
            cust_id: "",
            custno: "",
            company: "",
            address1: "",
            address2: "",
            city: "",
            state: "",
            zip: "",
            country: "",
            phone1: "",
            phone2: "",
            cell: "",
            fax: "",
            email: "",
          },
        ],
        shipment_address: [
          {
            sh_title: "",
            sh_first: "",
            sh_last: "",
            sh_custno: "",
            sh_company: "",
            sh_address1: "",
            sh_address2: "",
            sh_city: "",
            sh_state: "",
            sh_zip: "",
            sh_country: "",
            sh_phone1: "",
            sh_phone2: "",
            sh_cell: "",
            sh_fax: "",
            sh_email: "",
            sh_cust_id: "",
          },
        ],
        return_address: [
          {
            rt_title: "",
            rt_first: "",
            rt_last: "",
            rt_comp_id: "",
            rt_custno: "",
            rt_company: "",
            rt_address1: "",
            rt_address2: "",
            rt_city: "",
            rt_state: "",
            rt_zip: "",
            rt_country: "",
            rt_phone1: "",
            rt_phone2: "",
            rt_cell: "",
            rt_fax: "",
            rt_email: "",
          },
        ],
        auto_generate_order_number: false,
        order_number: "",
        order_status: "",
        order_type_id: "",
        order_type: "",
        rma: "",
        ordered_date: "",
        customer_po: "",
        customer_number: "",
        customer_order_number: "",
        requested_ship_date: "",
        pay_term_id: "",
        fob: "",
        ritm: "",
        shipment_terms: "",
        employee_name: "",
        employee_id: "",
        new_hire: "",
        new_hire_date: "",
        shipping_priority: "",
        return_shipping_priority: "",
        signature_required: false,
        saturday_delivery: false,
        problem_description: "",
        so_notes: "",
        order_notes: "",
        flex_field: [],
        order_line: [],
      }),
        this.clearPartItem();
      this.clearReturnAddressData();
      this.clearShipAddressData();
      this.clearCustAddressData();
      this.$refs.projectForm?.resetValidation();
    },

    // Validation based of Line Type CHeck
    validateLineType() {
      let LineItemUsed = [...new Set(this.salesOrderJson.order_line?.map((item) => item.order_line_type))];

      for (let i = 0; i < LineItemUsed.length; i++) {
        for (let j = 0; j < this.LineTypeList.length; j++) {
          if (this.LineTypeList[j].so_type_id == LineItemUsed[i]) {
            if (this.shipCount == 0) {
              this.shippingReq = this.LineTypeList[j].to_ship == 1 ? true : false;
              this.shippingReq ? (this.shipCount = 1) : (this.shipCount = 0);
            }
            if (this.returnCount == 0) {
              this.returnReq = this.LineTypeList[j].to_return == 1 ? true : false;
              this.returnReq ? (this.returnCount = 1) : (this.returnCount = 0);
            }
          }
        }
      }
    },
    //Clear Flex
    clearFlexItem() {
      this.showFlexDialog = false;
      this.flex_field_Att = "";
      this.flex_value = "";
      this.$refs.flexForm?.resetValidation();
    },
  },
  components: {
    breadcrumbComp,
    addressComp,
    BackToTop,
  },
};
